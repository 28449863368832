.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
body {
  background: #E9E9E9;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

@font-face {
  font-family: "Raleway"; 
  src: local("RalewayBold"),
    url("./fonts/Raleway-Bold.ttf") format("truetype");
}