  .drop-container {
    position: relative;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 96px;
    width: 96px;
    border-radius: 10px;
    border: 2px dashed #555;
    color: #444;
    cursor: pointer;
    transition: background .2s ease-in-out, border .2s ease-in-out;
    
  }
  
  .drop-container2 {
    position: relative;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 96px;
    width: 96px;
    border-radius: 10px;
    border: 2px dashed red;
    color: #444;
    cursor: pointer;
    transition: background .2s ease-in-out, border .2s ease-in-out;
  }
  .searchWrapper{
    margin:  10px 25px;
  }
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 5px;

}
  .multiSelectContainer ul{
    margin: 0 25px;
  }
  
  input[type=file] {
    visibility: hidden;
    width: 96px;
  }
  
  input[type=file]::file-selector-button {
    display: none;
  }
  
  input[type=file]::file-selector-button:hover {
    background: #0d45a5;
  }